<template>

    <form class="user" @keyup.enter="user.name && user.password && login()">
        <div class="user__error" v-if="errorMessage">
            {{errorMessage}}
        </div>
         <div class="user__row">
            <div class="user__field">
                <span>Name</span>
                <input type="text" v-model="user.name" />
            </div>
        </div>
         <div class="user__row">
            <div class="user__field">
                <span>Password</span>
                <input type="password" v-model="user.password" />
            </div>
        </div>
        <div class="userActions">
            <div @click="user.name && user.password && login()" class="userActions__btn is-save">
                Login
            </div>
        </div>
    </form>
</template>

<script>
import {logIn} from "@/api/api";

export default {
    data() {
        return {
            user: {
                name: '',
                passowrd: '',
                token: null
            },
            errorMessage: false
        }
    },
    methods: {
        login(){
            if(this.user.name && this.user.password) {
                this.$store.commit("isLoading", true);
                let formData = new FormData();
                formData.append('name', this.user.name)
                formData.append('password', this.user.password);
                formData.append('token', this.generate_token(20));

               logIn(formData).then((response) => {
                    this.$store.commit("isLoading", false);
                   if (!response.data.error && response.data) {
                        localStorage.setItem('token', response.data);
                        this.$router.push('/')
                        this.$store.commit('setAdmin', true);
                   } else if(response.data && response.data.error) {
                    this.errorMessage = response.data.message;
                   }
               })
            }
        },       
        generate_token(length){
            //edit the token allowed characters
            var a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
            var b = [];  
            for (var i=0; i<length; i++) {
                var j = (Math.random() * (a.length-1)).toFixed(0);
                b[i] = a[j];
            }
            return b.join("");
        }
    }
}
</script>
<style scoped lang="less">
    form {
        max-width: 34rem;
        margin: 4rem auto;

        @media screen and (min-width:1240px) {
            margin: 15rem auto;
        }
    }

    .user {
        &__error {
            font-weight: bold;
            padding: 20px 0;
            font-size: 16px;
            text-align: left;
            color: red;
        }

        &__row {
            margin-bottom: 2rem;
             text-align: left;
        }

        input {
            height: 5rem;
            margin-top: .5rem;
        }
    }
</style>